// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//import chart";

require('@rails/ujs').start();
require("turbolinks").start()
require("jquery");
require('popper.js/dist/umd/popper');
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//=require bootstrap-sprockets
//= require chart
import 'bootstrap';
window.jQuery = $;
window.$ = $;

import './bootstrap_custom.js'
import "chart.js"
import './clipboard_custom.js'
global.Rails = Rails;
